@media (min-width: 768px) {
  .menu__container {
    width: 360px !important;
    /*top: 54px !important;*/
    position: absolute !important;
  }
  ._mainlayout .navbar {
    padding-top: 25px;
  }
  ._nav_left_text {
    width: 100px;
  }
}

@media (max-width: 767px) {
  ._chat-form-row.row {
    bottom: 72px !important;
    width: 100% !important;
  }
  /* .betslip__container.betslip__container._slip-collapsed {
    bottom: 0;
    max-width: 60px;
  } */
  /* .resultsContainer {
    padding: 5px;
  } */
  /* temporary top search */
  .searchBarContainer {
    width: 90%;
    float: right;
  }
  .header-sub-navigation {
    max-width: 260px !important;
    font-size: 11px;
  }
}

@media (max-width: 359px) {
  ._mobile-view-sports-nav {
    width: 100% !important;
  }
}
